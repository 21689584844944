<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Convention collective" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter une convention collective</div>
              </v-row>
            </v-card-title>

            <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom"
                    dense
                    v-model="name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom complet -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    nom complet
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom complet"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le lien -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    lien
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le lien"
                    dense
                    v-model="link"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'identifiant national -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    IDCC national
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="L'IDCC"
                    dense
                    v-model="idcc"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le numéro -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    numéro
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le numéro"
                    dense
                    v-model="number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'échelon -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    échelon
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox
                    on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="caGrade"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la catégorie -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    catégorie
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox
                  on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="caCategory"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le niveau -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    niveau
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox
                  on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="caLevel"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le coefficient -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    coefficient
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox
                  on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="caCoefficient"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- les points -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    points
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox
                  on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="caPoints"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { CollectiveAgreementService } from "@/service/user/collective_agreement_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddCollectiveAgreement",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      collectiveAgreementsService: null,

      /**le nom */
      name: null,
      /** le nom complet */
      label: null,
      /** le lien */
      link: null,
      /** L'idcc (identifiant national) */
      idcc: null,
      /** le numéro */
      number: null,
      /** l'échelon */
      caGrade: null,
      /** la catégorie */
      caCategory: null,
      /** le niveau */
      caLevel: null,
      /** Le coefficient */
      caCoefficient: null,
      /** les points */
      caPoints: null,
    };
  },
  methods: {
    async save() {
      // enregistrement de la convention collective
      if (this.collectiveAgreementHasChanged) {
        try {
          let entity = {};
          entity.name = this.name;
          entity.label = this.label;
          entity.link = this.link;
          entity.idcc = this.idcc;
          entity.number = this.number;
          entity.caGrade = this.caGrade;
          entity.caCategory = this.caCategory;
          entity.caLevel = this.caLevel;
          entity.caCoefficient = this.caCoefficient;
          entity.caPoints = this.caPoints;

          await this.collectiveAgreementsService.create(entity);

          this.$router.go(-1);
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de la convention collective : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },
  },
  computed: {
    canSave() {
      try {
        if (!this.name || this.name == "") {
          return false;
        }

        if (!this.label || this.label == "") {
          return false;
        }

        if (!this.link || this.link == "") {
          return false;
        }

        let changed = false;

        if (this.collectiveAgreementHasChanged) {
          changed = true;
        }

        return changed;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    
    /**retourne true si la convention collective est modifiée */
    collectiveAgreementHasChanged() {
      let changed = false;

      if (this.name) {
        changed = true;
      }

      if (this.label) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.collectiveAgreementsService = new CollectiveAgreementService(this.$api.getCollectiveAgreementApi());
    
    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);
  },
};
</script>

<style>
</style>